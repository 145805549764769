import { styled } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Slide } from "react-slideshow-image";
import img from "../../images/intro_banner.jpg";

const ImageBox = styled(Box)(({ theme }) => ({
  minHeight: "640px",
  [theme.breakpoints.down("lg")]: {
    minHeight: "700px",
  },
  [theme.breakpoints.down("md")]: {
    minHeight: "550px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "400px",
  },
  [theme.breakpoints.down("xs")]: {
    minHeight: "100px",
  },
}));

export const Intro = () => {
  const properties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 500,
    arrows: false,
    infinite: false,
    easing: "ease",
  };

  const slideImages = [img];

  return (
    <div>
      <Slide {...properties}>
        {slideImages.map((each, index) => (
          <div key={index} className="each-slide">
            <img
              className="lazy"
              style={{ width: "100%" }}
              src={each}
              alt="barako restaurante"
            />
          </div>
        ))}
      </Slide>
    </div>
  );
};
