import React from 'react';
import { Background, Parallax } from 'react-parallax';
import { Login } from '../components/Login/Login';
import background from '../images/PATRON.2000x2000.png';

export const LoginScreen = () => {
  return (
    <Parallax strength={500}>
      <Background>
        <div
          style={{
            height: 2000,
            width: 2000,
            backgroundImage: `url(${background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </Background>
      <div style={{ minHeight: '100vh' }}>
        <Login />
      </div>
    </Parallax>
  );
};
