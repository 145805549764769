import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import imgBlanco from '../../images/BARAKO_DIAPO.png';
import {
  DevelopedBy,
  FooterContainer,
  FooterLogo,
  FooterWrapper,
  IgAccount,
  LogoImg,
  SocialIconLink,
  SocialIcons,
  WebsiteRigths,
} from './FooterElements';

export const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <SocialIconLink
          href="https://instagram.com/barako_rest"
          target="_blank"
          arial-label="Instagram"
        >
          <FaInstagram />
          <IgAccount> @barako_rest</IgAccount>
        </SocialIconLink>
        <FooterLogo to="/" onClick={toggleHome}>
          <LogoImg src={imgBlanco} />
        </FooterLogo>
        <WebsiteRigths>
          Barako Restaurant & Lounge © {new Date().getFullYear()} All rights
          reserved.
        </WebsiteRigths>
        <SocialIcons>
          {/* <SocialIconLink href='/' target='_blank' arial-label='Facebook'>
                        <FaFacebook/>
                    </SocialIconLink> */}

          {/* <SocialIconLink href='/' target='_blank' arial-label='Twitter'>
                        <FaTwitter/>
                    </SocialIconLink>
                    <SocialIconLink href='/' target='_blank' arial-label='Youtube'>
                        <FaYoutube/>
                    </SocialIconLink> */}
        </SocialIcons>
        <DevelopedBy
          href="//sappitotech.com/"
          target="_blank"
          arial-label="Twitter"
        >
          Developed by Sappito Tech
        </DevelopedBy>
      </FooterWrapper>
    </FooterContainer>
  );
};
