import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GroupIcon from "@mui/icons-material/Group";
import { Grid, styled, Typography } from "@mui/material";
import React from "react";

const TitleTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem !important",
    letterSpacing: "3px !important",
  },
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem !important",
  },
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem !important",
    letterSpacing: "2px !important",
  },
}));

export const MenuItem = ({ item, isUSD, changeRate }) => {
  return (
    <>
      <Grid item xs={9} display="flex">
        <TitleTypography variant="h5" sx={{ color: "#666666" }}>
          {item.title}
          {item.isLowCarb && (
            <FavoriteIcon
              color="secondary"
              sx={{ ml: "2px", mb: "1px", fontSize: "15px" }}
            />
          )}
          {item.isBestSeller && (
            <FontAwesomeIcon
              icon={faAsterisk}
              color="rgb(163, 20, 24)"
              style={{ marginBottom: 3, marginLeft: 2, fontSize: "12px" }}
            />
          )}
          {item.isMultiperson && (
            <GroupIcon
              color="secondary"
              sx={{ ml: "2px", mb: "1px", fontSize: "15px" }}
            />
          )}
        </TitleTypography>
      </Grid>
      <Grid item xs={3}>
        <PriceTypography
          variant="h5"
          color="secondary"
          align="right"
          sx={{ letterSpacing: 1 }}
        >
          {`${isUSD ? "$" : "Bs."} ${
            isUSD ? item.price.toFixed(2) : (item.price * changeRate).toFixed(2)
          }`}
        </PriceTypography>
      </Grid>

      <Grid item xs={9} md={10} lg={10}>
        <DescriptionTypography
          variant="subtitle2"
          color="textSecondary"
          sx={{ mb: 4, color: "#bbbbbb" }}
        >
          {item.description}
        </DescriptionTypography>
      </Grid>
    </>
  );
};
