import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "../../hooks/useForm";

export const ContactForm = ({ classes }) => {
  const [{ name, email, message }, handleInputChange, reset] = useForm({
    name: "",
    email: "",
    message: "",
  });

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");

  const getRecaptchaScore = async (token) => {
    const url = `https://us-central1-sappito-tech-web-a72f6.cloudfunctions.net/sendRecaptcha?token=${token}`;
    const response = await fetch(url);
    console.log("response", response);
    const { score } = await response.json();
    console.log("score", score);
    return score;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setMessageError("");

    if (name.trim().length <= 1) {
      console.log("Please input name");
      setNameError("Ingresa tu nombre completo");
      return;
    }

    if (email.trim().length <= 1) {
      console.log("Please input email");
      setEmailError("Ingresa tu correo electrónico");
      return;
    }

    if (message.trim().length <= 1) {
      console.log("Please input message");
      setMessageError("Debes ingresar un mensaje");
      return;
    }

    console.log("Calling reCaptcha!");
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdGhKgcAAAAAJTkIKoxqav8xQijVMx-yOAPffoa", {
          action: "submit",
        })
        .then(function (token) {
          // Send form value as well as token to the server
          //   console.log(token)
          getRecaptchaScore(token).then((data) => {
            console.log(data);
          });
        });
    });

    reset();
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Grid container className={classes.grid} spacing={2}>
        <Grid item xs={12}>
          <Typography
            className={classes.title}
            variant="h3"
            color="primary"
            align="center"
            sx={{ marginTop: "40px" }}
          >
            Contacto
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.description}
            color="textSecondary"
            align="center"
            sx={{ marginTop: "30px", marginBottom: "20px" }}
          >
            Si necesitas más información sobre nosotros, no dudes en
            contactarnos:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.field}
            label="Nombre"
            placeholder="Ingresa tu nombre completo..."
            variant="outlined"
            required
            fullWidth
            name="name"
            onChange={handleInputChange}
            value={name}
            error={nameError !== ""}
            helperText={nameError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.field}
            label="Email"
            placeholder="Ingresa tu email..."
            variant="outlined"
            type="email"
            required
            fullWidth
            name="email"
            onChange={handleInputChange}
            value={email}
            error={emailError !== ""}
            helperText={emailError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.field}
            label="Mensaje"
            variant="outlined"
            required
            fullWidth
            multiline
            minRows={4}
            placeholder="Déjanos tu mensaje aquí..."
            name="message"
            onChange={handleInputChange}
            value={message}
            error={messageError !== ""}
            helperText={messageError}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            sx={{ marginTop: "20px" }}
          >
            {" "}
            Enviar{" "}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
