import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { drawerStyles } from "./drawerStyles";

const useStyles = makeStyles((theme) => drawerStyles(theme));

export const AdminDrawer = ({
  window,
  drawerWidth,
  handleDrawerToggle,
  isDrawerOpen,
}) => {
  const classes = useStyles(drawerWidth);

  const history = useHistory();
  const location = useLocation();

  const menuItems = [
    {
      text: "Tasa USD",
      icon: <AttachMoneyIcon color="secondary" />,
      path: "/dashboard/changeRate",
    },
    // {
    //   text: 'Quoting Wizard',
    //   icon: <AssignmentOutlined color="secondary" />,
    //   path: '/quoting_wizard',
    // },
    // {
    //   text: "Users",
    //   icon: <Person color="secondary" />,
    //   path: "/dashboard/users",
    // },
  ];

  const drawerContent = (
    <div>
      <Toolbar sx={{ height: "60px" }} />
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItemButton
            key={item.text}
            onClick={() => history.push(item.path)}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="admin options"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};
