import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { KidsMenu } from "../components/Menu/KidsMenu";
import { MenuBorderBox } from "../components/Menu/MenuBorderBox";
import { MenuImage } from "../components/Menu/MenuImage";
import { menuData } from "../data/menuData";
import footerImg from "../images/menu/menu_ASSETS-13.png";
import AdminService from "../services/admin.service";

const MenuContainer = styled(Container)(({ theme }) => ({
  paddingTop: "60px",
  paddingBottom: "60px",

  // paddingLeft: 100,
  // paddingRight: 100,
  // "& .css-1h5bm59-MuiContainer-root": {
  //   maxWidth: 1000,
  //   // paddingLeft: 100,
  //   // paddingRight: 100,
  // },
  [theme.breakpoints.down("xl")]: {
    maxWidth: "1000px !important",
  },
}));

export const MenuScreen = () => {
  const data = menuData;

  const [isLoading, setIsLoading] = useState(true);
  const [isUSD, setIsUSD] = useState(false);
  const [changeRate, setChangeRate] = useState(0.0);

  useEffect(() => {
    getChangeRate();
  }, []);

  const getChangeRate = async () => {
    const changeRate = await AdminService.getChangeRate();
    if (changeRate.value) {
      setChangeRate(changeRate.value);
      setIsLoading(false);
    }
  };

  const handleSwitchChange = ({ target }) => {
    setIsUSD(target.checked);
  };

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <MenuContainer>
      <Paper elevation={6}>
        <Grid
          container
          id="menu"
          spacing={5}
          justifyContent="center"
          alignItems="center"
        >
          {/* Tapas */}
          <Grid item xs={12} alignItems="center" sx={{ mt: "-40px" }}>
            <MenuImage src={data.tapas.image} alt="tapas" />
          </Grid>
          <Grid
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h4">Bs</Typography>
              <Switch
                checked={isUSD}
                onChange={handleSwitchChange}
                color="secondary"
                inputProps={{ "aria-label": "precio" }}
              />
              <Typography variant="h4">$</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.tapas}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Salads */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.salads.image} alt="ensaladas" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.salads}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Starters  */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.starters.image} alt="entradas" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.starters}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Sides */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.sides.image} alt="raciones" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.sides}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Kids Menu */}
          <Grid item xs={12} alignItems="center">
            <KidsMenu isUSD={isUSD} changeRate={changeRate} />
          </Grid>
          {/* Angus */}
          <Grid item xs={12} alignItems="center">
            <MenuBorderBox
              data={data.angus}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Meat */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.meat.image} alt="res" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.meat}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Pork */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.pork.image} alt="cerdo" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.pork}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Espetadas */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.espetadas.image} alt="espetadas" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.espetadas}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Guarniciones */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.guarns.image} alt="guarniciones" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.guarns}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Fish */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.fish.image} alt="pescados y mariscos" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.fish}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Chicken */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.chicken.image} alt="pollo" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.chicken}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Desserts */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={data.desserts.image} alt="postres" />
          </Grid>
          <Grid item xs={12}>
            <MenuBorderBox
              data={data.desserts}
              isUSD={isUSD}
              changeRate={changeRate}
            />
          </Grid>
          {/* Footer */}
          <Grid item xs={12} alignItems="center">
            <MenuImage src={footerImg} alt="barako eventos" />
          </Grid>
        </Grid>
      </Paper>
    </MenuContainer>
  );
};
