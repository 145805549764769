export const menuStyles = (theme) => ({
  container: {
    paddingTop: 60,
    maxWidth: 1300,
    paddingBottom: 60,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  title: {
    marginTop: 40,
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
});
