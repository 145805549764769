import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutContainer } from "../components/general/layout/LayoutContainer";
import { ChangeRateScreen } from "../pages/Dashboard/ChangeRateScreen";

export const DashboardRoutes = () => {
  return (
    <LayoutContainer>
      <Switch>
        <Route
          exact
          path="/dashboard/changeRate"
          component={ChangeRateScreen}
        />

        <Redirect to="/dashboard/changeRate" />
      </Switch>
    </LayoutContainer>
  );
};
