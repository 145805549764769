import tapasImg from "../images/menu/menu_ASSETS-01.png";
import saladsImg from "../images/menu/menu_ASSETS-02.png";
import startersImg from "../images/menu/menu_ASSETS-03.png";
import sidesImg from "../images/menu/menu_ASSETS-04.png";
import meatImg from "../images/menu/menu_ASSETS-06.png";
import porkImg from "../images/menu/menu_ASSETS-07.png";
import espetadasImg from "../images/menu/menu_ASSETS-08.png";
import guarnsImg from "../images/menu/menu_ASSETS-09.png";
import fishImg from "../images/menu/menu_ASSETS-10.png";
import chickenImg from "../images/menu/menu_ASSETS-11.png";
import dessertsImg from "../images/menu/menu_ASSETS-12.png";

export const menuData = {
  tapas: {
    title: "TAPAS",
    image: tapasImg,
    items: [
      {
        title: "TRIO BARAKO",
        description:
          "CARPACCIO DE LOMITO Y PULPO ACOMPAÑADOS DE ENSALADA CAPRESE",
        price: 18.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CARPACCIO DE LOMITO",
        description:
          "FINOS CORTES DE CARNE, ADEREZADO CON SALSA PESTO Y QUESO PARMESANO",
        price: 13.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CARPACCIO DE PULPO",
        description:
          "FINAS LONJAS DE PULPO SABORIZADAS CON ALCAPARRA Y TOMATES",
        price: 14.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "ENVOLTINIS DE BERENJENAS",
        description:
          "ENVOLTINIS DE BERENJEAS BRASEADAS, RELLENAS DE QUESO DE CABRA Y GRATINADAS CON QUESO PARMESANO",
        price: 11.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CEVICHE",
        description: "CEVICHE CON LECHE DE TIGRE, ACOMPAÑADO DE TOSTONES",
        price: 16.65,
        isLowCarb: true,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CAMARONES AL AJILLO",
        description: null,
        price: 16.85,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PULPO AL AJILLO",
        description: null,
        price: 25.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CHAMPIÑONES AL AJILLO",
        description: "CHAMPIÑONES SALTEADOS CON AJO Y FINAS HIERBAS",
        price: 13.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CHAMPIÑONES AL VINO",
        description: null,
        price: 13.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CONCHAS DE PAPA",
        description:
          "PAPAS HORNEADAS CON QUESO MOZARELLA BAÑADA CON TROZOS DE TOCINETA Y CEBOLLÍN",
        price: 8.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  salads: {
    title: "ENSALADAS",
    image: saladsImg,
    items: [
      {
        title: "CABRAL",
        description:
          "LECHUGA CON REBANADAS DE PERA, MANÍ, QUESO DE CABRA GRATINADO Y ADEREZADO CON SALSA DE MIEL MOSTAZA",
        price: 11.35,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "CÉSAR DE BERRO",
        description:
          "BERRO, CROUTONS, QUESO PARMESANO, ADEREZADAS CON SALSA CÉSAR",
        price: 12.45,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "CÉSAR DE LECHUGA",
        description:
          "LECHUGA, CROUTONS, QUESO PARMESANO, ADEREZADAS CON SALSA CÉSAR",
        price: 12.45,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "AGUACATE Y PALMITO",
        description: "AGUACATE Y PALMITO ADEREZADO CON VINAGRETA",
        price: 12.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "MIXTA",
        description:
          "LECHUGA, BERRO, CEBOLLA, AGUACATE Y TOMATE ADEREZADO CON VINAGRETA",
        price: 11.25,
        isLowCarb: true,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CAPRESE",
        description:
          "RODAJAS DE QUESO MOZARELLA CON RUEDAS DE TOMATES ADEREZADAS CON SALSA PESTO",
        price: 11.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "SANDRITA",
        description:
          "BERRO, TOMATE, AGUACATE, CEBOLLA Y PALMITO ADEREZADA CON SALSA SANDRITA",
        price: 11.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  starters: {
    title: "ENTRADAS",
    image: startersImg,
    items: [
      {
        title: "AREPAS",
        description: "AREPAS ASADAS O FRITAS",
        price: 5.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "NATA",
        description: null,
        price: 4.85,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "QUESO",
        description: "QUESO BLANCO A LA PLANCHA",
        price: 9.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CASABE CON AJO",
        description: null,
        price: 4.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "TEQUEÑOS",
        description:
          "5 TEQUEÑOS DE HARINA DE TRIGO RELLENOS DE QUESO BLANCO ACOMPAÑADOS CON SALSA AGRIDULCE",
        price: 9.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "TEQUEÑOS DE YUCA",
        description:
          "5 BASTONES DE YUCA RELLENOS DE QUESO BLANCO ACOMPAÑADOS CON SALSA DE PAPELÓN",
        price: 7.45,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "TEQUEÑOS DE MANDOCA",
        description:
          "5 TEQUEÑOS DE PLÁTANO MADURO RELLENOS DE QUESO BLANCO ACOMPAÑADOS DE SALSA DE PAPELÓN",
        price: 7.35,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "DEDOS DE QUESO",
        description:
          "5 DEDOS DE QUESO MOZARELLA EMPANIZADO Y ACOMPAÑADO CON SALSA BBQ",
        price: 9.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "EMPANADAS DE CARNE",
        description: null,
        price: 8.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  sides: {
    title: "RACIONES",
    image: sidesImg,
    items: [
      {
        title: "PLÁTANO AL HORNO",
        description: "PLÁTANO HORNEADO CON QUESO BLANCO GRATINADO",
        price: 8.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "TAJADAS",
        description: "PLÁTANO MADURO FRITO CON QUESO BLANCO GRATINADO",
        price: 7.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "TOSTONES",
        description: "PLÁTANO CRUJIENTE CON MOJITO PARRILLERO",
        price: 7.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PATACONES",
        description: "TOSTONES CON SALSA ROSADA Y QUESO BLANCO RALLADO",
        price: 8.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PAPAS FRITAS",
        description: null,
        price: 6.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CONGRI",
        description: "CARAOTAS CON ARROZ Y MOJITO PARRILLERO",
        price: 6.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "YUCA SANCOCHADA",
        description: "YUCA SANCOCHADA CON MOJITO PARRILLERO",
        price: 4.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "MORCILLA (UND)",
        description: "MORCILLA A LA PARRILLA",
        price: 3.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CHISTORRA (UND)",
        description: "CHISTORRA A LA PARRILLA",
        price: 3.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CHORIZO (UND)",
        description: "CHORIZO A LA PARRILLA",
        price: 3.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  angus: {
    title: "RES ANGUS",
    items: [
      {
        title: "VACÍO DE RES",
        description: "CORTE DE VACÍO ANGUS SELLADO Y FILETEADO A LA PARRILLA",
        price: 27.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PUNTA ANGUS",
        description: "4 BISTECS DE PUNTA ANGUS A LA PARRILLA",
        price: 26.35,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "BIFE ANGUS",
        description: "CORTE DE SOLOMO ANGUS SELLADO Y FILETEADO A LA PARRILLA",
        price: 24.95,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
    ],
  },
  meat: {
    title: "RES",
    image: meatImg,
    items: [
      {
        title: "PARRILLA BARAKO",
        description:
          "2 BISTECS DE SOLOMO, 1/2 POLLO DESHUESADO, RACK DE COSTILLAS BBQ, 2 CHORIZOS, 2 MORCILLAS",
        price: 57.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "BIFE ESTILO BARAKO",
        description: "CORTE DE SOLOMO SELLADO Y FILETEADO A LA PARRILLA",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "SOLOMO (P/P)",
        description: "4 BISTECS DE SOLOMO A LA PARRILLA",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "LOMITO A LA CHAMPIÑÓN",
        description: "CORTE DE LOMITO EN SALSA DE CHAMPIÑÓN",
        price: 24.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "HAMBURGUESA DE PUNTA",
        description:
          "CARTE DE PUNTA TRASERA, MEZCLA DE QUESO CHEDDAR Y MOZZARELLA, SALSA CHIMICHURRI, CEBOLLA MORADA, TOCINETA Y PAPAS FRITAS",
        price: 11.45,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "CENTRO DE LOMITO",
        description: "CORTE DE LOMITO SELLADO Y FILETEADO A LA PARRILLA",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  pork: {
    title: "CERDO",
    image: porkImg,
    items: [
      {
        title: "LOMO DE CERDO",
        description: "CORTE DE CERDO A LA PARRILLA",
        price: 19.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "COSTILLAS BBQ ST. LOUIS",
        description:
          "COSTILLAS DE CERDO BAÑADAS EN SALSA BBQ Y ACOMPAÑADAS CON PAPAS FRITAS",
        price: 25.05,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      // {
      //   title: "COSTILLAS BABY BACK",
      //   description:
      //     "COSTILLAS DE CERDO BAÑADAS EN SALSA BBQ ACOMPAÑADAS CON PAPAS FRITAS",
      //   price: 21.75,
      //   isLowCarb: false,
      //   isBestSeller: false,
      //   isMultiperson: false,
      // },
    ],
  },
  espetadas: {
    title: "ESPETADAS",
    image: espetadasImg,
    items: [
      {
        title: "LOMITO",
        description:
          "TROZOS DE LOMITO EN PINCHO A LA PARRILLA ACOMPAÑADO CON MILHO FRITO",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "SOLOMO",
        description:
          "TROZOS DE SOLOMO EN PINCHO A LA PARRILLA ACOMPAÑADO CON MILHO FRITO",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "CERDO",
        description:
          "TROZOS DE CERDO EN PINCHO A LA PARRILLA ACOMPAÑADO CON MILHO FRITO",
        price: 19.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "POLLO",
        description:
          "TROZOS DE POLLO EN PINCHO A LA PARRILLA ACOMPAÑADO CON MILHO FRITO",
        price: 16.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "ASADO DE RES",
        description:
          "TROZOS DE ASADO DE TIRA EN PINCHO A LA PARRILLA ACOMPAÑADO CON MILHO FRITO",
        price: 23.45,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  guarns: {
    title: "GUARNICIONES",
    image: guarnsImg,
    items: [
      {
        title: "YUCA FRITA",
        description: "CHURRO DE YUCA FRITA",
        price: 5.75,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "PAPAS COLOMBIANAS",
        description: "PAPAS CRUJIENTES SALTEADAS CON HIERBAS",
        price: 6.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PAPAS TRUFADAS",
        description: "PAPAS FRITAS CON QUESO PARMESANO Y ACEITE DE TRUFA",
        price: 9.35,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "BROCHETA DE VEGETALES",
        description:
          "PINCHO CON CEBOLLA, CALABACÍN BERENJENA, PIÑA, HINOJO Y CHAMPIÑONES A LA PARRILLA",
        price: 12.05,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "GUACAMOLE",
        description: null,
        price: 10.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "MILHO FRITO",
        description: "POLENTA DE MAÍZ FRITO",
        price: 4.65,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "MAÍZ",
        description: "MAZORCA DE MAÍZ A LA PARRILLA",
        price: 4.85,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "PAPA RELLENA",
        description:
          "PAPA HORNEADA CON QUESO MOZARELLA, BAÑADA CON TROZOS DE TOCINETA Y TIRAS DE CEBOLLÍN",
        price: 10.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  fish: {
    title: "PESCADOS Y MARISCOS",
    image: fishImg,
    items: [
      {
        title: "BROCHETA DE MERO",
        description: "TROZOS DE MERO EN PINCHO A LA PARRILLA",
        price: 26.15,
        isLowCarb: true,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "BROCHETAS DE SALMÓN",
        description: "TROZOS DE SALMÓN EN PINCHO A LA PARRILLA",
        price: 19.25,
        isLowCarb: true,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CALDEIRADA DE MERO",
        description: "GUISO DE MERO CON PAPAS",
        price: 31.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: true,
      },
      {
        title: "PAELLA",
        description: null,
        price: 47.25,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: true,
      },
      {
        title: "PARRILLA MAR Y TIERRA",
        description:
          "CALAMARES, CAMARONES, LOMITO, CHISTORRA Y POLLO ACOMPAÑADA DE PAPAS FRITAS",
        price: 55.05,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: true,
      },
      {
        title: "ARROZ A LA MARINERA",
        description: null,
        price: 45.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: true,
      },
      {
        title: "ASOPADO",
        description: "ASOPADO DE MARISCOS",
        price: 45.95,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: true,
      },
    ],
  },
  chicken: {
    title: "POLLO",
    image: chickenImg,
    items: [
      {
        title: "1/2 POLLO DESHUESADO",
        description: "1/2 POLLO DESHUESADO A LA PARRILLA",
        price: 16.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "1/2 POLLO A LA BBQ",
        description: "1/2 POLLO DESHUESADO A LA PARRILLA CON SALSA BBQ",
        price: 16.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
  desserts: {
    title: "POSTRES",
    image: dessertsImg,
    items: [
      {
        title: "BROWNIE CON HELADO",
        description:
          "BROWNIE CON SIROPE DE CHOCOLATE, CREMA CHANTILLÍ Y HELADO DE MANTECADO",
        price: 9.35,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "QUESILLO",
        description: "FLAN DULCE",
        price: 6.75,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CHIPS AND COOKIE",
        description:
          "GALLETA HORNEADA CON CHISPAS DE CHOCOLATE ACOMPAÑADA DE HELADO",
        price: 8.35,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "HAMBURGUESA DE HELADO",
        description: "PROFITEROL RELLENO DE HELADO Y CREMA DE AVELLANAS",
        price: 9.75,
        isLowCarb: false,
        isBestSeller: true,
        isMultiperson: false,
      },
      {
        title: "SOUFFLÉ",
        description: "SOUFLÉ CON TOPE DE SIROPE DE CHOCOLATE",
        price: 6.15,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "CREPE",
        description: "CREPE RELLENA DE CREAMA DE AVELLANAS",
        price: 7.55,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
      {
        title: "TOCINILLO DEL CIELO",
        description: "TOCINILLO DEL CIELO CRIOLLO",
        price: 6.75,
        isLowCarb: false,
        isBestSeller: false,
        isMultiperson: false,
      },
    ],
  },
};
