import React, { useState } from "react";
import { Background, Parallax } from "react-parallax";
import "../App.css";
import { AboutSection } from "../components/AboutSection/AboutSection";
import { ContactSection } from "../components/ContactSection/ContactSection";
import { Footer } from "../components/Footer";
import { GallerySection } from "../components/GallerySection";
import { Intro } from "../components/Intro/Intro";
import { MenuSection } from "../components/MenuSection/MenuSection";
import { Navbar } from "../components/Navbar";
import { Sidebar } from "../components/Sidebar";
import background from "../images/PATRON.2000x2000.png";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <Navbar toggleSidebar={toggleSidebar} />
      <Intro />
      {/* <MenuSection /> */}
      <Parallax strength={500}>
        <Background>
          <div
            style={{
              height: 2000,
              width: 2000,
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        </Background>
        <AboutSection />
        <GallerySection />
      </Parallax>
      <MenuSection />
      {/* <div className='mainRaised'>
                <InfoSection { ...homeWho }/>
                <InfoSection { ...homeHow }/>
                <HowSection />
            </div> */}
      <Parallax strength={500}>
        <Background>
          <div
            style={{
              height: 2000,
              width: 2000,
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
        </Background>
        <ContactSection />
      </Parallax>
      <Footer />
    </div>
  );
};
