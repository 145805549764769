import { Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { aboutStyles } from "./aboutStyles";

const useStyles = makeStyles((theme) => aboutStyles(theme));

export const AboutSection = () => {
  const classes = useStyles();

  return (
    // <Parallax strength={500}>
    //   <Background>
    //     <div
    //       style={{
    //         height: 2000,
    //         width: 2000,
    //         backgroundImage: `url(${background})`,
    //         backgroundRepeat: "no-repeat",
    //         backgroundSize: "cover",
    //       }}
    //     />
    //   </Background>
    <Container id="about" className={classes.container}>
      <Grid
        container
        className={classes.grid}
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={10}>
          <Typography
            className={classes.title}
            variant="h3"
            color="primary"
            align="center"
          >
            Barako Restaurant and Lounge
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            className={classes.description}
            sx={{ lineHeight: 2, marginTop: 5, marginBottom: 4 }}
            align="center"
          >
            Su propuesta gastronómica se inspira en exquisitos cortes de carne
            que dan placer a los sentidos, destacándose por servir alimentos
            frescos y de primera calidad. Se distinguen por su excelente
            servicio, su meticuloso cuidado al detalle y su especial atención en
            la ejecución de sus platillos. Su misión es crear experiencias
            gastronómicas únicas e inolvidables para los invitados, en un
            ambiente cálido y acogedor, logrando marcas que se traducen en
            sonrisas.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.description} align="center">
            ¡Simplemente perfecto!
          </Typography>
        </Grid>
      </Grid>
    </Container>
    // </Parallax>
  );
};
