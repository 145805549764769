import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { LoginForm } from "./LoginForm";
import { loginFormStyles } from "./loginFormStyles";

const useStyles = makeStyles((theme) => loginFormStyles(theme));

export const Login = () => {
  const classes = useStyles();

  return (
    <Container id="login" className={classes.container}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6} lg={5}>
          <LoginForm classes={classes} />
        </Grid>
      </Grid>
    </Container>
  );
};
