export const drawerStyles = (theme) => ({
  drawerTitle: {
    padding: theme.spacing(3),
  },
  drawer: {
    width: (drawerWidth) => drawerWidth,
  },
  drawerPaper: {
    width: (drawerWidth) => drawerWidth,
  },
  logo: {
    width: '100%',
  },
});
