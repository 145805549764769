export const loginFormStyles = (theme) => ({
  container: {
    marginTop: 80,
    maxWidth: 1500,
    paddingBottom: 60,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  card: {
    justifyContent: 'center',
    borderRadius: 20,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  grid: {
    marginBottom: 60,
  },
  title: {
    marginTop: 40,
  },
});
