import { Container, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { AdminNavBar } from '../admin-nav-bar/AdminNavBar';
import { AdminDrawer } from '../drawer/AdminDrawer';
import { layoutStyles } from './layoutStyles';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => layoutStyles(theme));

export const LayoutContainer = ({ children }) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className={classes.root}>
      <AdminNavBar
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        isDrawerOpen={isDrawerOpen}
      />
      <AdminDrawer
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        isDrawerOpen={isDrawerOpen}
      />
      <Container className={classes.page}>
        <Toolbar />
        {children}
      </Container>
    </div>
  );
};
