import { Container, Grid, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import img from "../../images/menu/menu_ASSETS-05.png";
import { MenuImage } from "./MenuImage";

const MenuContainer = styled(Container)(({ theme }) => ({
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
}));

export const KidsMenu = ({ data, isUSD, changeRate }) => {
  return (
    <>
      <Box
        display="flex"
        //   m="auto"
        // maxWidth='xs'
        minHeight={120}
        sx={{ backgroundColor: "#DACD8D" }}
      >
        <MenuContainer sx={{ display: "flex" }}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={4} alignItems="center">
              <Typography variant="subtitle1" align="center" color="secondary">
                MENÚ INFANTIL
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div
                style={{
                  borderLeft: "4px solid white",
                  height: "50px",
                }}
              ></div>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                NUGGETS + PAPAS FRITAS
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ letterSpacing: 0 }}
              >
                {`${isUSD ? "$" : "Bs."} ${
                  isUSD ? (7.95).toFixed(2) : (7.95 * changeRate).toFixed(2)
                }`}
              </Typography>
            </Grid>
          </Grid>
        </MenuContainer>
      </Box>
      <MenuImage src={img} alt="cortes a la parrilla" />
    </>
  );
};
