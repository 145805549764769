import styled from 'styled-components';

export const GalleryContainer = styled.div`
  color: #0c0c0c;
  /* background: rgb(182, 129, 80, 0.4); */

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-height: 900px;
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  /* max-width: 540px; */
  padding-top: 0;
  margin-top: 60px;
  height: 100px;
`;

export const GalleryGridContainer = styled.div`
  /* height: 300px; */
  margin-top: 20px;
  margin-bottom: 100px;
  margin-left: 40px;
  margin-right: 40px;
`;
