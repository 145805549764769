import { Typography } from "@mui/material";
import React from "react";
import Gallery from "react-grid-gallery";
import img2 from "../../images/venue/IMG_9898.JPG";
import img1 from "../../images/venue/IMG_9907.JPG";
import img3 from "../../images/venue/IMG_9910.JPG";
import img4 from "../../images/venue/IMG_9926.JPG";
import img5 from "../../images/venue/IMG_9932.JPG";
import {
  GalleryContainer,
  GalleryGridContainer,
  GalleryWrapper,
  TextWrapper,
} from "./GalleryElements";

const IMAGES = [
  {
    src: img1,
    thumbnail: img1,
    thumbnailWidth: 400,
    thumbnailHeight: 212,
  },
  {
    src: img2,
    thumbnail: img2,
    thumbnailWidth: 400,
    thumbnailHeight: 212,
  },
  {
    src: img3,
    thumbnail: img3,
    thumbnailWidth: 400,
    thumbnailHeight: 212,
  },
  {
    src: img4,
    thumbnail: img4,
    thumbnailWidth: 410,
    thumbnailHeight: 212,
  },
  {
    src: img5,
    thumbnail: img5,
    thumbnailWidth: 410,
    thumbnailHeight: 212,
  },
];

export const GallerySection = () => {
  return (
    <GalleryContainer id="gallery">
      <GalleryWrapper>
        <TextWrapper>
          <Typography variant="h3" color="primary" align="center">
            Galería
          </Typography>
        </TextWrapper>
        <GalleryGridContainer>
          <Gallery
            images={IMAGES}
            enableLightbox={true}
            enableImageSelection={false}
            rowHeight={280}
            backdropClosesModal
            // maxRows={3}
            // currentImage={3}
            // isOpen={ true}
          />
        </GalleryGridContainer>
      </GalleryWrapper>
    </GalleryContainer>
  );
};
