import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const YellowBox = styled(Box)(
  ({ theme }) => `
  border: 2px solid ${theme.palette.secondary.main};
  margin-top: 60px;
`
);

export const BoxTitle = styled(Typography)(
  ({ theme }) => `
  margin-top: -25px;
  margin-left: 10px;

  ${theme.breakpoints.down("sm")} {
    margin-top: -20px;
  }

  span {
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
  }
`
);

export const ItemsContainer = styled(Box)`
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
`;
