import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { useForm } from "../../hooks/useForm";

export const LoginForm = ({ classes }) => {
  const [{ email, password }, handleInputChange, reset] = useForm({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { logIn } = useAuth();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim().length <= 1) {
      console.log("Please input email");
      setError("Ingresa un correo electrónico");
      return;
    }

    if (email.trim().length <= 1) {
      console.log("Please input password");
      setError("Ingresa tu constraseña");
      return;
    }

    try {
      setError("");
      setLoading(true);

      await logIn(email, password);
      history.replace("/dashboard/changeRate");
      return;
    } catch (err) {
      setError("Credenciales invalidas");
      console.log(err);
    }

    setLoading(false);
    reset();
  };

  return (
    <Card className={classes.card} elevation={2}>
      <CardContent>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container className={classes.grid} spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                variant="h3"
                color="primary"
                align="center"
                sx={{ marginTop: "40px" }}
              >
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.field}
                label="Email"
                placeholder="Email"
                variant="outlined"
                type="email"
                required
                fullWidth
                name="email"
                onChange={handleInputChange}
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.field}
                label="Password"
                placeholder="Password"
                variant="outlined"
                type="password"
                required
                fullWidth
                name="password"
                onChange={handleInputChange}
                value={password}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                disabled={loading}
              >
                {" "}
                Log in{" "}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {error && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};
