import { Wrapper } from "@googlemaps/react-wrapper";
import { Box } from "@mui/material";
import React from "react";
import { Map } from "./Map";
import { Marker } from "./Marker";

export const MapWrapper = () => {
  const location = {
    lat: 10.5021191,
    lng: -66.8503944,
  };

  return (
    <Box sx={{ width: "100%", height: 300 }}>
      <Wrapper apiKey={process.env.REACT_APP_FIREBASE_API_KEY}>
        <Map
          center={location}
          zoom={18}
          style={{ flexGrow: "1", height: "100%" }}
        >
          <Marker position={location} />
        </Map>
      </Wrapper>
    </Box>
  );
};
