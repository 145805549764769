export const layoutStyles = (theme) => ({
  root: {
    display: 'flex',
    margin: 20,
  },
  page: {
    // background: '#f9f9f9',
    width: '100%',
  },
});
