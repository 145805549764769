import React from "react";

export const Map = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

// return (

//   <GoogleMapReact
//     bootstrapURLKeys={{ key: process.env.REACT_APP_FIREBASE_API_KEY }}
//     defaultCenter={location}
//     defaultZoom={18}
//   >
//     <LocationOnIcon
//       sx={{ fontSize: 60, color: "rgba(163, 20, 24)" }}
//       lat={location.lat}
//       lng={location.lng}
//     />
//     {/* <LocationPin
//   lat={location.lat}
//   lng={location.lng}
//   text={location.address}
// /> */}
//   </GoogleMapReact>
// );
