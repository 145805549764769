import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { AppRouter } from "./routers/AppRouter";

let theme = createTheme({
  palette: {
    primary: {
      main: "rgb(35,31,32)",
    },
    secondary: {
      main: "rgb(182, 129, 80, 0.7)",
    },
    text: {
      primary: "#231f20",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
    h1: {
      fontSize: "5.5rem",
      fontWeight: 500,
      fontFamily: "Regis",
      letterSpacing: 2,
    },
    h2: {
      fontSize: "3.3rem",
      fontWeight: 500,
      fontFamily: "Regis",
      letterSpacing: 2,
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 500,
      fontFamily: "Regis",
      letterSpacing: 2,
    },
    h4: {
      fontSize: "2.7rem",
      fontFamily: "Bebas Neue",
      letterSpacing: 2,
    },
    h5: {
      fontSize: "1.2rem",
      fontFamily: "Myriad Pro",
      letterSpacing: 6,
    },
    subtitle1: {
      fontSize: "1.3rem",
      fontFamily: "Myriad Pro",
      letterSpacing: 4,
    },
    subtitle2: {
      fontSize: "0.8rem",
      fontFamily: "Myriad Pro",
      letterSpacing: 3,
    },
  },
});

theme = responsiveFontSizes(theme, { factor: 3.2 });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
