export const aboutStyles = (theme) => ({
  container: {
    marginTop: 100,
    maxWidth: 1300,
    marginBottom: 100,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 60,
      paddingRight: 60,
      marginTop: 60,
      marginBottom: 60,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  title: {
    marginTop: 40,
  },
  description: {
    marginTop: 30,
    marginBottom: 20,
    letterSpacing: 3,
    lineHeight: 2,
  },
  field: {},
  button: {
    marginTop: 20,
  },
  image: {
    width: "100%",
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      paddingTop: 60,
      paddingLeft: 0,
    },
  },
  logo: {
    width: "60%",
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      paddingTop: 60,
      paddingLeft: 0,
    },
  },
});
