import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';

export const FooterContainer = styled.section`
  width: 100%;
  background-color: rgb(35, 31, 32, 1);
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0px auto 0px auto;
  padding-top: 30px;
  padding-bottom: 60px;
  flex-direction: column;
`;

export const FooterLogo = styled(LinkS)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
  text-decoration: none;
`;

export const WebsiteRigths = styled.small`
  color: #ffff;
  margin-bottom: 0px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin-bottom: 16px;
`;

export const SocialIconLink = styled.a`
  display: flex;
  color: #fff;
  font-size: 24px;
`;

export const DevelopedBy = styled.a`
  color: #fff;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
`;

export const IgAccount = styled.a`
  margin-top: 2px;
  margin-left: 10px;
  display: block;
  color: #fff;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: 200px;
  margin-left: 0px;
  margin-bottom: 16px;
  object-fit: contain;
`;
