import React from "react";
import {
  CloseIcon,
  Icon,
  MenuSidebarLink,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarWrapper,
} from "./SidebarElements";

export const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggleSidebar}>
      <Icon onClick={toggleSidebar}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="about"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Nosotros
          </SidebarLink>

          <SidebarLink
            to="gallery"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Galería
          </SidebarLink>
          <MenuSidebarLink to="/menu" offset={-80}>
            Menú
          </MenuSidebarLink>
          <SidebarLink
            to="contact"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Contacto
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
