import { Container, Grid, styled } from "@mui/material";
import React from "react";
import { MenuItem } from "./MenuItem";
import { BoxTitle, ItemsContainer, YellowBox } from "./menuStyles";

const BoxContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    paddingLeft: "80px",
    paddingRight: "80px",
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: "45px",
    paddingRight: "45px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

export const MenuBorderBox = ({ data, isUSD, changeRate }) => {
  return (
    <BoxContainer>
      <YellowBox>
        <BoxTitle variant="h4" color="secondary">
          {data.title && <span>{data.title}</span>}
        </BoxTitle>
        <ItemsContainer>
          <Grid container spacing={0}>
            {data.items.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                isUSD={isUSD}
                changeRate={changeRate}
              />
            ))}
          </Grid>
        </ItemsContainer>
      </YellowBox>
    </BoxContainer>
  );
};
