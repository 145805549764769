import { Menu } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { navbarStyles } from "./navbarStyles";

const useStyles = makeStyles((theme) => navbarStyles(theme));

export const AdminNavBar = ({
  drawerWidth,
  handleDrawerToggle,
  isDrawerOpen,
}) => {
  const classes = useStyles(drawerWidth);

  const { logOut } = useAuth();

  const handleLogOut = () => {
    logOut();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
      className={classes.appbar}
    >
      {/* <AppBar className={classes.appbar}> */}
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} display="flex">
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Menu />
          </IconButton>
          <Container>
            <Typography variant="h3">Admin</Typography>
          </Container>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Button color="inherit" onClick={handleLogOut}>
            Log out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
