import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminService from "../../services/admin.service";

export const ChangeRateScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [changeRate, setChangeRate] = useState(0.0);
  const [showAlert, setShowAlert] = useState(false);
  const [isAlertSuccess, setIsAlertSuccess] = useState(true);

  useEffect(() => {
    getChangeRate();
  }, []);

  const getChangeRate = async () => {
    const changeRate = await AdminService.getChangeRate();
    if (changeRate.value) {
      setChangeRate(changeRate.value);
      setIsLoading(false);
    }
  };

  const handleInputChange = ({ target }) => {
    setChangeRate(target.value);
  };

  const handleSave = async () => {
    const result = await AdminService.updateChangeRate(Number(changeRate));
    if (result) {
      setIsAlertSuccess(true);
      setShowAlert(true);
    } else {
      setIsAlertSuccess(false);
      setShowAlert(true);
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  return (
    <Container id="price">
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Tasa de cambio:</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="value"
            value={changeRate}
            variant="outlined"
            type="number"
            size="small"
            onChange={handleInputChange}
          />
        </Grid>
        {showAlert && (
          <Grid item xs={12}>
            <Alert severity={isAlertSuccess ? "success" : "error"}>
              {isAlertSuccess
                ? "Tasa de cambio actualizada!"
                : "Hubo un error al actualizar la tasa de cambio"}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSave} color="secondary">
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
