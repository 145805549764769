export const contactStyles = (theme) => ({
  container: {
    marginTop: 80,
    maxWidth: 1500,
    paddingBottom: 60,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  card: {
    justifyContent: "center",
    borderRadius: 20,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  grid: {
    marginBottom: 60,
  },
  title: {
    marginTop: 40,
  },
  description: {
    marginTop: 30,
    marginBottom: 20,
    letterSpacing: 2,
  },
  field: {},
  button: {
    marginTop: 20,
  },
  image: {
    width: "100%",
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      paddingTop: 60,
      paddingLeft: 0,
    },
  },
  logo: {
    width: "80%",
    paddingLeft: 60,
    [theme.breakpoints.down("md")]: {
      //   paddingTop: 60,
      paddingLeft: 0,
    },
  },
});
