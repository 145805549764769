import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MapWrapper } from "../StoreLocation/MapWrapper";
import { ContactForm } from "./ContactForm";
import { contactStyles } from "./contactStyles";

const useStyles = makeStyles((theme) => contactStyles(theme));

export const ContactSection = () => {
  const classes = useStyles();

  return (
    <Container id="contact" className={classes.container}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Card
            className={classes.card}
            elevation={2}
            sx={{ borderRadius: "20px" }}
          >
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <ContactForm classes={classes} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <MapWrapper />
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Typography variant="h5" color="primary" align="center">
                        Horario:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 3 }}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <Typography align="center">Lunes:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary" align="center">
                            Cerrado
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="center">
                            Martes a Jueves:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary" align="center">
                            11:30 am - 11:00 pm
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="center">Sábados:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary" align="center">
                            11:30 am - 12:00 am
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="center">Domingos:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography color="textSecondary" align="center">
                            11:30 am - 9:00 pm
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
