import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import ScrollToTop from "../helpers/ScrollToTop";
import { Home } from "../pages/Home";
import { LoginScreen } from "../pages/LoginScreen";
import { MenuScreen } from "../pages/MenuScreen";
import { DashboardRoutes } from "./DashboardRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Switch>
          <PublicRoute exact path="/" component={Home} />
          <PublicRoute exact path="/login" component={LoginScreen} />
          <PublicRoute exact path="/menu" component={MenuScreen} />
          <PrivateRoute path="/" component={DashboardRoutes} />

          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};
