import { Button, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { menuStyles } from "./menuStyles";

const useStyles = makeStyles((theme) => menuStyles(theme));

export const MenuSection = () => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "rgb(35,31,32)" }}>
      <Container id="menu" className={classes.container}>
        <Grid
          container
          className={classes.grid}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              variant="h3"
              style={{ color: "#fff" }}
              align="center"
            >
              Menú
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{ m: 4 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Link
                to={"/menu"}
                underline="none"
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" color="secondary" size="large">
                  Ver menú
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
