import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore/lite";
import app from "../firebase";

const db = getFirestore(app);
const changeRateCollection = "change_rate";
const docRef = doc(
  db,
  changeRateCollection,
  process.env.REACT_APP_CHANGE_RATE_DOCUMENT_ID
);

const getChangeRate = async () => {
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  }
};

const updateChangeRate = async (value) => {
  try {
    await setDoc(docRef, { value: value });
    return true;
  } catch (error) {
    return false;
  }
};

const AdminService = {
  getChangeRate,
  updateChangeRate,
};

export default AdminService;
