import { Box } from "@mui/material";
import React from "react";

export const MenuImage = ({ src, alt }) => {
  return (
    <Box
      display="flex"
      m="auto"
      // maxWidth='xs'
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: "100%",
          height: undefined,
        }}
      />
    </Box>
  );
};
