import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      component={(props) =>
        !currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard/changeRate" />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
